import React from "react";
import "../../App.css";
import CardProjects from "../CardProjects";

function Project() {
  return (
    <div id="top">
      <CardProjects />
    </div>
  );
}

export default Project;
